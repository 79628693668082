import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Error from "./Error";

const HowOptions = [
  "郵遞至學校的資料包",
  "國史教育中心（香港）網站",
  "知史網站/Facebook/Instagram等社交媒體平台",
  "欣學教育（ALS）應用程式（Edu DynamiX）/Facebook等社交媒體平台",
  "教師 / 同事 / 親戚 / 朋友等他人轉告",
];

const How = ({ field, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <FormGroup>
      <Label htmlFor={field}>請問您從什麼途徑得知今次活動？（可選多項）</Label>
      <br />
      {HowOptions.map((option, idx) => (
        <FormGroup check key={idx}>
          <Label check>
            <Input
              type="checkbox"
              name={field}
              onChange={handleChange}
              onBlur={handleBlur}
              value={option}
            />
            {option}
          </Label>
          <br />
        </FormGroup>
      ))}
      {/* <Label>
        <Input
          type="text"
          name={field}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="其他（請註明）"
        />
      </Label> */}
      <br />
      <Error field={field} errors={errors} touched={touched} />
    </FormGroup>
  );
};

export default How;
