import React from "react";
import { FormGroup, Label, Input, FormText, Row, Col } from "reactstrap";
import Error from "./Error";
import "./Choice.css";

import choice1_img from "../images/1.png";
import choice2_img from "../images/2.png";
import choice3_img from "../images/3.png";
import choice4_img from "../images/4.png";
import choice5_img from "../images/5.png";

import cb_checked from "../images/check_box_checked.svg";
import cb_unchecked from "../images/check_box_unchecked.svg";
// import cb_checked from "../images/radio_checked.svg";
// import cb_unchecked from "../images/radio__unchecked.svg";

const choices = [
  {
    value: 1,
    name: "屈原<br>（戰國）",
    image: choice1_img,
  },
  {
    value: 2,
    name: "司馬遷<br>（西漢）",
    image: choice2_img,
  },
  {
    value: 3,
    name: "杜甫<br>（唐朝）",
    image: choice3_img,
  },
  {
    value: 4,
    name: "辛棄疾<br>（南宋）",
    image: choice4_img,
  },
  {
    value: 5,
    name: "秋瑾<br>（清朝）",
    image: choice5_img,
  },
];

const Choice = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => (
  <FormGroup>
    <Row form style={{ margin: "0" }}>
      <Label htmlFor={field}>年度歷史人物候選人</Label>
    </Row>
    <Row form style={{ margin: "0" }}>
      <FormText>
        請選擇下列一位心儀的年度中國歷史人物，然後按「提交」，即完成投票！
        <br />* 有關五位候選人物簡介，請參閱本中心網頁：
        <br className="rwd-break" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cnhe-hk.org/chinesehistoricalfigureelection2021-1st-phase/"
        >
          年度中國歷史人物選舉2021 第一階段：歷史人物選舉
        </a>
      </FormText>
    </Row>
    <Row form style={{ margin: "0" }}>
      {choices.map((choice, idx) => {
        // eslint-disable-next-line
        const checked = values.choice == choice.value;
        return (
          <Col lg={4} xs={6} key={idx}>
            <Label check className="ChoiceElement">
              <Input
                type="radio"
                name={field}
                value={choice.value}
                checked={checked}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="card">
                <img src={choice.image} alt={choice.name} className="poster" />
                <div className="label">
                  <img
                    src={checked ? cb_checked : cb_unchecked}
                    alt="check box"
                    className="radio"
                  />
                  <div dangerouslySetInnerHTML={{ __html: choice.name }} />
                </div>
              </div>
            </Label>
          </Col>
        );
      })}
    </Row>
    <Error field={field} errors={errors} touched={touched} />
  </FormGroup>
);

export default Choice;
