import React from "react";
import { Container } from "reactstrap";

const PreVotingBanner = () => {
  return (
    <Container
      style={{
        top: 0,
        backgroundColor: "var(--accent-light)",
        color: "#ffffff",
        fontSize: "3rem",
        fontWeight: 900,
        zIndex: 999,
      }}
      className="position-sticky text-center"
    >
      11月5日開始接受投票
    </Container>
  );
};

export default PreVotingBanner;
